<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>
  <div class="page" v-else-if="showModuloForm === true">
    <Panel lineColor="var(--primary-color)">
      <template v-slot:options>
        <FormButtonGroup align="end" spaced>
          <FormButton
            text="Importar imagens"
            type="primario"
            @click="onUpload"
          />
        </FormButtonGroup>
      </template>
      <DataTable
        :headers="headers"
        :endpoint="endpoint"
        sortBy="descricao"
        :sortDesc="false"
        ref="datatable"
        search
      >
        <template v-slot:data_criacao="{ col }">
          {{ renderData(col) }}
        </template>
        <template v-slot:status="{ col }">
          <span v-if="col.error">{{ col.error }}</span>
          <v-progress-linear
            v-else
            :value="renderProgress(col)"
            height="20"
            rounded
            color="primario"
          >
            <template v-slot:default="{ value }">
              <strong class="white--text">{{ Math.ceil(value) }}%</strong>
            </template>
          </v-progress-linear>
        </template>
        <template v-slot:idprocessamento="{ row }">
          <FormButtonMenu
            icon
            :options="tableOptions"
            :loading="loadingRow[row.idimportacaoimagem]"
            @click="op => selectOption(row, op)"
          >
            <v-icon size="15">fas fa-ellipsis-v</v-icon>
          </FormButtonMenu>
        </template>
      </DataTable>
    </Panel>
    <transition name="slide-popup" mode="out-in">
      <router-view class="view" @close="onClose"></router-view>
    </transition>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable'
import Panel from '@/components/Panel'
import FormButton from '@/components/form/FormButton'
import FormButtonMenu from '@/components/form/FormButtonMenu'
import FormButtonGroup from '@/components/form/FormButtonGroup'
import MarketingModules from '@/components/MarketingModules'
import * as moment from 'moment'
import { mapGetters } from 'vuex'
import { ModulesEnum } from '@/store/actions/modulesEnum'

export default {
  name: 'ImagemProdutoTable',
  components: {
    DataTable,
    Panel,
    FormButton,
    FormButtonMenu,
    FormButtonGroup,
    MarketingModules
  },
  data: () => ({
    headers: [
      { text: 'Nome', value: 'descricao' },
      { text: 'Data de Envio', value: 'data_criacao', align: 'center' },
      { text: 'Processamento', value: 'status', align: 'center' },
      { text: 'Status', value: 'status_processamento', align: 'center' },
      {
        text: 'Opções',
        value: 'idprocessamento',
        clickable: false,
        align: 'center'
      }
    ],
    tableOptions: [{ text: 'Relatório de processamento', value: 'download' }],
    loadingRow: {},
    modulosForm: [
      ModulesEnum.ORCAMENTO_WEB,
      ModulesEnum.ORCAMENTO_APP,
      ModulesEnum.VENDAMAIS_WEB,
      ModulesEnum.VENDAMAIS_APP,
      ModulesEnum.WEB_COMMERCE,
      ModulesEnum.COMMERCE_APP,
      ModulesEnum.PORTFOLIO_WEB
    ]
  }),
  computed: {
    ...mapGetters(['getFornecedorId', 'moduloFornecedorAtivo']),
    endpoint() {
      return `/api/v4/product-image/list/${this.getFornecedorId}`
    },
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    }
  },
  created() {},
  methods: {
    onUpload() {
      this.$router.push('imagens-produto/upload')
    },
    onClose() {
      this.$refs.datatable.reload()
      this.loadingRow = {}
    },
    selectOption(item, option) {
      switch (option.value) {
        case 'download':
          return this.downloadFile(item.url_report_processamento)
      }
    },
    renderData(col) {
      return col ? moment(col).format('DD/MM/YYYY HH:MM') : ''
    },
    renderProgress(col) {
      return (col.completed / col.size) * 100
    },
    downloadFile(url) {
      window.open(url, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped></style>
